// import 'hammerjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import Amplify, {Auth} from 'aws-amplify';
import awsmobile from './aws-exports';
import {environment} from './app/pages/environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


if (environment.production) {

  environment.version = 'D - 2.2'

  // DESENVOLVIMENTO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://ypiemxiz4ffxzoxqkvi4weksye.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-rxhybrvfyrheffez6znfsov5xy'
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:32e66e98-ec54-4a16-9568-60ef5f13b915',
      region: 'us-east-2',
      aws_user_pools_id: 'us-east-2_WoTPNs0jN',
      aws_user_pools_web_client_id: '2ofljrlfbgfteiapnocd4ugmft',
    },
    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });

} else {

  environment.version = 'D - 2.2'

  // DESENVOLVIMENTO
  Amplify.configure(awsmobile);

  Amplify.configure({
    aws_appsync_graphqlEndpoint: 'https://ypiemxiz4ffxzoxqkvi4weksye.appsync-api.us-east-2.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-2',
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    aws_appsync_apiKey: 'da2-rxhybrvfyrheffez6znfsov5xy'
  });

  Amplify.configure({
    Auth: {
      identityPoolId: 'us-east-2:32e66e98-ec54-4a16-9568-60ef5f13b915',
      region: 'us-east-2',
      aws_user_pools_id: 'us-east-2_WoTPNs0jN',
      aws_user_pools_web_client_id: '2ofljrlfbgfteiapnocd4ugmft',
    },
    API: {
      graphql_headers: async () => {
        return {Authorization: (await Auth.currentSession()).getIdToken().getJwtToken()};
      }
    }
  });

}
